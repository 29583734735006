import { EExamType } from '@/enums'
import router from '@/router'
import paths from '@/router/paths'
import { MUTATIONS, store } from '@/store'
import moment from 'moment'
import NetworkService, { Endpoint } from './NetworkService'
import ICommentPost from '@/interfaces/ICommentPost'
import ISelectOption from '@/interfaces/ISelectOption'

export default class ExamsService {
    private network

    constructor() {
        this.network = new NetworkService()
    }

    static TimeTolerance = 600 // 10 minutes in seconds

    static installmentOptions: ISelectOption[] = Array(12).fill('').map((elem: any, index: number) => {
        return index == 0 ? {
            id: '1',
            title: 'À vista'
        } : {
            id: (index + 1).toString(),
            title: `${index + 1} vezes`
        }
    })

    static appealStatus = {
        'pending': 'Pendente',
        'rejected': 'Rejeitado',
        'approved': 'Aprovado'
    }

    static appealStatusClasses = {
        'pending': 'warning',
        'rejected': 'danger',
        'approved': 'success'
    }

    list = (params: object) => {
        return this.network.get(Endpoint.exams, params)
    }
    listMyExams = (params: object = {}) => {
        return this.network.get(Endpoint.myExams, params)
    }
    listCategories = async () => {
        const res = await this.network.get(Endpoint.examCategories)
        let data = res.data.map((elem: any) => ({ id: elem, title: elem, active: false }))
        data.unshift({ id: '', title: 'Todas', active: true })
        data.push({ id: 'free', title: 'Gratuitos', active: false })

        return data
    }
    show = (id: number | string) => {
        return this.network.get(`${Endpoint.exams}/${id}`)
    }
    prepareExam(data: any) {

        // TODA A LÓGICA DO HORÁRIO E TEMPO DE PROVA
        const duration = data.duration
        const timeTolerance = ExamsService.TimeTolerance
        const durationWithTolerance = duration + timeTolerance

        let remainingTime = 0

        // data.duration = durationWithTolerance

        if (data?.exam_datetime) {
            const diff = moment().diff(moment(data.exam_datetime), 'seconds')
            if (diff >= 0) {
                remainingTime = duration - diff + timeTolerance
                remainingTime = remainingTime > 0 ? remainingTime : 0
            }
            data.formattedDate = moment(data.exam_datetime).format('DD/MM/YYYY')
            data.formattedTime = moment(data.exam_datetime).format('HH:mm')
            data.formattedDatetime = moment(data.exam_datetime).format('[Dia ]DD[ de ]MMMM[ de ]YYYY[ às ]HH:mm')
            data.deadlineTime = moment(new Date(data?.exam_datetime).getTime() + (durationWithTolerance * 1000)).format('HH:mm')
            data.deadlineDatetime = moment(new Date(data?.exam_datetime).getTime() + (durationWithTolerance * 1000)).format('DD/MM/YYYY [às] HH:mm')
            data.deadline = moment(new Date(data?.exam_datetime).getTime() + (durationWithTolerance * 1000))
            data.dateWithTolerance = moment(new Date(data?.exam_datetime).getTime() + (ExamsService.TimeTolerance * 1000))
            // data.duration = duration
            // data.canAppeal = moment().isBefore(moment(data.exam_datetime).add(24, 'hours'))
        }



        data.durationWithTolerance = durationWithTolerance
        data.remainingTime = remainingTime
        data.formattedDuration = moment.utc(duration * 1000).format('HH[h]mm[m]')
        data.timeTolerance = timeTolerance
        data.formattedTimeTolerance = `${timeTolerance / 60} minutos`

        // FORMATA O PREÇO
        data.formattedPrice = data.price ? data.price.toCurrency().replace('R$', '') : data.price
        if (data.max_installments) {
            data.formattedPricePerInstallment = (data.price / data.max_installments).toCurrency().replace('R$', '')
        }

        // FORMATA AS QUESTÕES MULTIPLA ESCOLHA
        let answers = [] as any[]
        // 65 é o código decimal para a letra A na tabela ascii
        const startIndex = 65
        // eu somo 65 + qtd de alternativas e vejo até qual letra vai ter
        const lastIndex = startIndex + data.alternatives_per_question
        // no loop eu converto o codigo pra letra e add no array
        // não, eu nao usei o Gepeto 😎
        for (let index = startIndex; index < lastIndex; index++) {
            if (index < 90) {
                answers.push(String.fromCharCode(index))
            }
        }
        // SETA AS ALTERNATIVAS
        data.alternatives = data.exam_type == EExamType.multiple ? answers : ['C', 'E']

        // FORMATA OS PDFS
        data.exam_pdf = data.exam_pdf.map((elem: any) => {
            const urlSplit = elem.url.split('/')
            return { ...elem, title: urlSplit[urlSplit.length - 1], downloadUrl: `${process.env.VUE_APP_API_BASE_URL}exampdf/${elem.id}` }
        })
        data.resolution_pdf = data.resolution_pdf.map((elem: any) => {
            const urlSplit = elem.url.split('/')
            return { ...elem, title: urlSplit[urlSplit.length - 1], downloadUrl: `${process.env.VUE_APP_API_BASE_URL}resolutionpdfs/${elem.id}` }
        })

        return data
    }
    prepareComingSoonExam = (data: any) => {
        data.formattedDate = moment(data.date).format('DD/MM/YYYY')
        return data
    }
    listRanking = (params: object) => {
        return this.network.get(Endpoint.examRanking, params)
    }
    answerExam = (params: object) => {
        return this.network.post(Endpoint.examAnswers, params)
    }
    getAnswerSheet = (params: object) => {
        return this.network.get(Endpoint.examAnswers, params)
    }
    showMyExam = async (id: string) => {
        const res = await this.network.get(`${Endpoint.myExams}/${id}`)

        if (res.data.expire_at) {
            res.data.formatted_expire_at = moment(res.data.expire_at).format('DD/MM/YYYY')
        }

        return res
    }
    finishExam = (params: object) => {
        return this.network.post(Endpoint.finishExam, params)
    }
    listAttempts = (params: object) => {
        return this.network.get(Endpoint.examAttempts, params)
    }
    getFreeExam = async () => {
        const res = await this.list({ price: 0, active: 1 })
        if (res.data.length) {
            router.push(`${paths.landing.exam}/${res.data[0]?.id}`)
        } else {
            alert('Não há nenhum simulado gratuíto disponível no momento')
        }
    }
    getExamsAcceptedTerms = () => {
        const data = store.state.acceptedTerms
        if (data) {
            return data
        } else {
            const obj = {} as any
            const session = store.state.session
            if (session?.id) {
                obj[session.id] = {
                    exams: []
                }
            }
            return obj
        }
    }
    setExamsAcceptedTerms = (data: any) => {
        store.commit(MUTATIONS.SAVE_EXAMS, data)
    }
    checkExamAcceptedTerms = (id: any) => {
        const acceptedTerms = this.getExamsAcceptedTerms()
        const session = store.state.session
        if (session && acceptedTerms[session.id]) {
            return acceptedTerms[session.id].exams.some((elem: any) => id == elem.exam_id)
        }
        return false
    }
    listAppeals = (params: object) => {
        return this.network.get(Endpoint.appeals, params)
    }
    showAppeal = (id: number | string) => {
        return this.network.get(`${Endpoint.appeals}/${id}`)
    }
    createAppeal = (params: object) => {
        return this.network.post(Endpoint.appeals, params)
    }
    listInstitutes = () => {
        return this.network.get(Endpoint.institutes)
    }
    listBenches = () => {
        return this.network.get(Endpoint.benches)
    }
    listLevels = () => {
        return this.network.get(Endpoint.levels)
    }
    listOffices = () => {
        return this.network.get(Endpoint.offices)
    }
    listTestimonials = (params: object) => {
        return this.network.get(Endpoint.testimonials, params)
    }
    listFilters = () => {
        return this.network.get(Endpoint.filters)
    }
    preparePerformanceStatus = (corretAnswer: string, userAnswer: string, mode: string) => {
        let res
        if (corretAnswer == userAnswer) res = 'right'
        if (corretAnswer != userAnswer) res = 'wrong'

        if (!mode && !userAnswer) return 'Estudar'
        if (!mode || !userAnswer) return
        if (mode == 'chute') return 'Estudar'
        if (res == 'right' && mode == 'duvida') return 'Revisar'
        if (res == 'right' && mode == 'certeza') return 'Aprendeu'
        if (res == 'wrong' && mode == 'duvida') return 'Estudar'
        if (res == 'wrong' && mode == 'certeza') return 'Estudar'
    }
    createComment = (params: ICommentPost) => {
        return this.network.postMultipart(`${Endpoint.testimonials}`, params)
    }
    deleteComment = (id: string | number) => {
        return this.network.delete(`${Endpoint.testimonials}/${id}`)
    }
    getFormattedComment(comment: any) {
        try {
            comment.formatted_created_at = moment(comment.created_at).format('DD/MM/YYYY - HH[h]mm')
        } catch (e) {
            console.log(e)
        } finally {
            return comment
        }
    }
    getExamScores = (params: object) => {
        return this.network.get(Endpoint.examScores, params)
    }
}