import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = {
  key: 0,
  class: "form-label text-label"
}
const _hoisted_4 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_5 = ["value", "data-maska"]
const _hoisted_6 = ["data-maska", "type", "maxlength", "placeholder", "min"]
const _hoisted_7 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
            _createTextVNode(_toDisplayString($props.label) + " ", 1),
            ($props.required)
              ? (_openBlock(), _createElementBlock("i", _hoisted_4, "*"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "labelContentEnd")
    ]),
    ($props.readOnly)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          class: "form-control form-disabled",
          value: $props.value,
          disabled: "",
          style: _normalizeStyle($props.inputStyle),
          "data-maska": $props.mask
        }, null, 12, _hoisted_5)), [
          [_directive_maska]
        ])
      : ($props.model)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            ref: "textInput",
            class: _normalizeClass(["form-control", { 'is-invalid': $props.model.$error }]),
            "data-maska": $props.mask,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.model.$model) = $event)),
            type: $props.type,
            maxlength: $props.maxLength,
            placeholder: $props.placeholder,
            min: $props.minDate,
            style: _normalizeStyle($props.inputStyle)
          }, null, 14, _hoisted_6)), [
            [_directive_maska],
            [
              _vModelDynamic,
              $props.model.$model,
              void 0,
              { trim: true }
            ]
          ])
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("small", null, _toDisplayString($options.errorMessage), 1)
    ]),
    _renderSlot(_ctx.$slots, "content")
  ]))
}