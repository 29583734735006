export function sortCols(columns: Array<any>, colIndex: string | number) {
    try {
        return columns.map((r, index) => {
            let order = null as string | null
            let loading = false
            if (colIndex == index) {
                loading = true
                order = r.order == null ? 'ASC' : r.order == 'ASC' ? 'DESC' : null
            }
            const l = {
                ...r,
                order,
                loading

            }
            return l
        })
    } catch (e) {
        console.log(e)
        return columns
    }

}
export function dataURLtoFile(dataurl: string, filename: string): File {

    var arr = dataurl.split(',')
    var type
    var mime: any = arr[0].match(/:(.*?);/)
    type = mime[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type });

}

export function toggleModal(id: string) {
    const button = document.getElementById(`${id}_modal_button`)
    if (button) {
        button?.click()
    }
}

export function showModal(id: string) {
    // Adicionando classes para mostrar a modal e o backdrop
    const modal = document.getElementById(id);
    const backdrop = document.getElementsByClassName('modal-backdrop')[0];

    console.log(modal)
    console.log(backdrop)
    if (modal && backdrop) {
        modal.classList.add('show');
        backdrop.classList.add('show');
    }
}
export function hideModal(id: string) {
    // Removendo classes para esconder a modal e o backdrop
    const modal = document.getElementById(id);
    const backdrop = document.getElementsByClassName('modal-backdrop')[0];
    if (modal && backdrop) {
        modal.classList.remove('show');
        backdrop.classList.remove('show');
    }
}